:root {
  --primaryDefault: #2c6fae;
  --primaryLightest: #e0eaf4;
  --primaryLight: #a4c5e5;
  --primaryHover: #5191cd;
  --blackBackground: #f0f3f6;
  --gray: #97a0a9;
  --solitude: #e9ecef;
  --white: #ffffff;
  --paginationBorder: #e2e6e8;
}

@font-face {
  font-family: 'Lato-Regular';
  src: local('Lato-Regular'), url(../fonts/Lato-Regular.ttf) format('truetype');
  -webkit-font-smoothing: antialiased;
}

* {
  font-synthesis: none !important;
}

body {
  font-family: 'Lato-Regular' !important;
  letter-spacing: 0.03125rem;
}

.scrollbar::-webkit-scrollbar {
  width: 0.5rem;
  height: 0px;
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: var(--primaryLightest);
  border: 1px solid var(--primaryLight);
  border-radius: 0.25rem;
}

.star_block:hover .tooltiptext {
  visibility: visible !important;
}

.dataTable tr td:first-child {
  padding-left: 1.5rem;
}

.dataTable tr td:last-child {
  padding-right: 1.5rem;
}

.navLink:hover .navLink_icon {
  transition: all 0.5s ease-out;
  color: var(--primaryDefault);
}

.navLink--active,
.navLink--active .navLink_icon {
  color: var(--primaryDefault) !important;
}

.border-positive {
  --tw-border-opacity: 1;
  border-color: rgb(15 194 133 / var(--tw-border-opacity));
}

.border-warning {
  --tw-border-opacity: 1;
  border-color: rgb(250 192 50 / var(--tw-border-opacity));
}

.rating-mark-item-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 4px;
  width: 250px;
  padding: 4px 12px;
  position: relative;
}

.rating-mark-text {
  z-index: 1;
  word-break: break-all;
}

.rating-mark-item-background-part {
  position: absolute;
  height: 100%;
  top: 0;
  z-index: 0;
}

.rating-mark-item-background-part.positive {
  border-style: solid;
  border-width: 1px 0 1px 1px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  left: 0;
}

.rating-mark-item-background-part.warning {
  border-style: solid;
  border-width: 1px 0 1px 0;
  left: calc(100% / 3);
}

.rating-mark-item-background-part.negative {
  border-style: solid;
  border-width: 1px 1px 1px 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  right: 0;
}
