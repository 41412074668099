.custom-input:disabled,
.custom-input[readonly] {
  background-color: var(--solitude);
  opacity: 1;
}

.dropdown:target {
  border-color: var(--primaryHover);
}

.acceptTerms:hover input ~ .checkmark {
  background-color: var(--primaryDefault);
}

.acceptTerms input:checked ~ .checkmark {
  background-color: var(--primaryDefault);
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.acceptTerms input:checked ~ .checkmark:after {
  display: block;
}

.acceptTerms .checkmark:after {
  top: 0.15rem;
  right: 0.35rem;
  width: 0.35rem;
  height: 0.6rem;
  border: solid var(--white);
  border-width: 0 0.15rem 0.15rem 0;
  -webkit-transform: rotate(40deg);
  -ms-transform: rotate(40deg);
  transform: rotate(40deg);
}

.custom-date_input:hover:enabled {
  border-color: var(--primaryHover);
  cursor: pointer;
}

.custom-date_input::-webkit-calendar-picker-indicator {
  width: 1rem;
  height: 0.5625rem;
  margin-top: 0.24rem;
  background: url('../icons/downArrow.svg') no-repeat;
}

.form-check-input:checked {
  background-color: var(--primaryDefault);
  border-color: var(--primaryDefault);
}

.radio-checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.radioInputs__item input:checked ~ .radio-checkmark:after {
  display: block;
}

.radioInputs__item input:checked ~ .radio-checkmark {
  background-color: var(--primaryDefault);
}

.radioInputs__item .radio-checkmark:after {
  top: 0.3125rem;
  right: 0.3125rem;
  width: 0.5rem;
  height: 0.5rem;
  background-color: var(--blackBackground);
  border-radius: 100%;
}

.checkboxInputs__item:hover input ~ .checkbox-checkmark {
  background-color: var(--primaryDefault);
}

.checkboxInputs__item input:checked ~ .checkbox-checkmark {
  background-color: var(--primaryDefault);
}

.checkbox-checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.checkboxInputs__item input:checked ~ .checkbox-checkmark:after {
  display: block;
}

.checkboxInputs__item .checkbox-checkmark:after {
  bottom: 0.3125rem;
  right: 0.3125rem;
  width: 0.375rem;
  height: 0.75rem;
  border: solid var(--white);
  border-width: 0 0.125rem 0.125rem 0;
  -webkit-transform: rotate(40deg);
  -ms-transform: rotate(40deg);
  transform: rotate(40deg);
}

/* Switcher */

.dropdownItem:hover .radioCheck {
  background-color: var(--primaryDefault);
  border-color: var(--primaryDefault);
}

.radioCheck:after {
  content: '';
  position: absolute;
  display: none;
}

.dropdownItem input:checked ~ .radioCheck:after {
  display: block;
}

.dropdownItem .radioCheck:after {
  top: 0.3rem;
  right: 0.3rem;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 100%;
}

.dropdownItem .radioCheck--active:after,
.dropdownItem:hover .radioCheck:after {
  background-color: var(--white);
  display: block;
}

/* Slider */

.slider:before {
  position: absolute;
  content: '';
  height: 0.8125rem;
  width: 0.8125rem;
  right: 0.125rem;
  bottom: 0.0925rem;
  background-color: var(--white);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

input:checked + .slider {
  background-color: var(--primaryDefault);
}

input:focus + .slider {
  box-shadow: 0 0 0.0625rem var(--primaryDefault);
}

input:checked + .slider:before {
  -webkit-transform: translateX(-0.9375rem);
  -ms-transform: translateX(-0.9375rem);
  transform: translateX(-0.9375rem);
}

.slider.round:before {
  border-radius: 50%;
}

.pagination {
  margin-top: 10px;
  font-weight: 600;
}

.pagination .page-item {
  border: 1px solid var(--paginationBorder);
  margin: 0 5px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  height: 40px;
  width: 40px;
  justify-content: center;
}

.pagination .page-item .page-link {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination .page-item:not(.disabled):hover,
.pagination .page-item.active {
  background: var(--primaryDefault);
  color: var(--white);
}

.pagination .page-item.disabled,
.pagination .page-item.disabled .page-link {
  cursor: not-allowed;
}
