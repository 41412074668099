.room video {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg); /* Safari and Chrome */
  -moz-transform: rotateY(180deg); /* Firefox */
}

.progressTime {
  height: inherit;
}

.video-control-list-container.camera:hover {
  display: flex;
}

.video-control_local-container-video:hover .video-control-list-container.camera {
  display: flex;
}

.video-control-list-container.audio:hover {
  display: flex;
}

.video-control_local-container-audio:hover .video-control-list-container.audio {
  display: flex;
}

.video-control-audio-container.mic:hover {
  display: flex;
}

.video-control-audio-list-mic:hover .video-control-audio-container.mic {
  display: flex;
}

.video-control-audio-container.speaker:hover {
  display: flex;
}

.video-control-audio-list-speaker:hover .video-control-audio-container.speaker {
  display: flex;
}

.spectator-count:hover .spectator-list-container {
  display: flex;
}
